import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { TFunc } from 'src/common/types';

export const filteringProperties = (t: TFunc): PropertyFilterProperty[] => [
  {
    propertyLabel: t('account'),
    key: 'account',
    groupValuesLabel: t('group_value_label', { label: t('account') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('cost_center'),
    key: 'costCenter',
    groupValuesLabel: t('group_value_label', { label: t('cost_center') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('company_code'),
    key: 'companyCode',
    groupValuesLabel: t('group_value_label', { label: t('company_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('location_code'),
    key: 'locationCode',
    groupValuesLabel: t('group_value_label', { label: t('location_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('product_code'),
    key: 'productCode',
    groupValuesLabel: t('group_value_label', { label: t('product_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('project_code'),
    key: 'projectCode',
    groupValuesLabel: t('group_value_label', { label: t('project_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('channel_type'),
    key: 'channelType',
    groupValuesLabel: t('group_value_label', { label: t('channel_type') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('allocation_function'),
    key: 'allocationFns',
    groupValuesLabel: t('group_value_label', {
      label: t('allocation_function'),
    }),
    operators: [':', '!:'],
    defaultOperator: ':',
  },
  {
    propertyLabel: t('drivers'),
    key: 'drivers',
    groupValuesLabel: t('group_value_label', { label: t('drivers') }),
    operators: [':', '!:'],
    defaultOperator: ':',
  },
];
